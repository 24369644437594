import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1200,
      CanvasHeight: 850,
      CanvasBorderWeight: 0,
      CanvasBorderColor: '#757575',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1200,
      CanvasChartHeight: 850,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    Status: [
      { type: null,       bgColor: '#ffffff', textColor: '#bcbcbc'},
      { type: 'Cutting',  bgColor: '#9AD0E9', textColor: '#44A9DF'},
      { type: 'Assembly', bgColor: '#4492CF', textColor: '#FFFFFF'},
      { type: 'Painting', bgColor: '#F2DA77', textColor: '#44A9DF'},
      { type: 'PE',       bgColor: '#CFD88C', textColor: '#FFFFFF'},
      { type: 'Erection', bgColor: '#91C643', textColor: '#FFFFFF'},
      
    ],
    Border: [
      {
        type: 'UNIT',
        shape: 'ellipse',
        width: 22,
        height: 10,
        color: 'gray',
        stroke: .2,
        textSize: 8,
        padding: 0.5,
      },
      {
        type: 'BLK',
        shape: 'ellipse',
        width: 26,
        height: 12,
        color: 'gray',
        stroke: .2,
        textSize: 9,
        padding: 1,
      },
      {
        type: 'PE',
        shape: 'rect',
        width: 30,
        height: 12,
        color: 'gray',
        stroke: .2,
        textSize: 10,
        padding: 1,
      },
    ],

    // ----------------------------------------------- Filter Text
    Text: {
      Text: [
        { text: 'Module', x: 118, y: 50, font: 'roboto', class: 'regular', size: 20, color: '#44A9DF', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 40, y: 50, font: 'roboto', class: 'regular', size: 20, color: '#44A9DF', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'PBF COMPESSOR AIR COOLER - TRAIN 4',
          x: 40,
          y: 67,
          font: 'roboto',
          style: 'regular',
          size: 14,
          color: '#757575',
          align: 'start'
        },
      ]
    },
    // -----------------------------------------------------------


    DataItems: [
      { MOD: '41M001', BLK: '3A1', TYPE: 'UNIT', DEP: 25.3, LEN: 16.3, HEI: 1.5, WT: 158.7, STATUS: 'PE', EREC_PLAN: null, EREC_ACTUAL: null, },
      { MOD: '41M001', BLK: '3B1', TYPE: 'UNIT', DEP: 21.6, LEN: 16.3, HEI: 5.9, WT: 130.1, STATUS: 'PE', EREC_PLAN: null, EREC_ACTUAL: null, },
      { MOD: '41M001', BLK: '3B9', TYPE: 'UNIT', DEP: 4.3, LEN: 16.3, HEI: 5.9, WT: 11.8, STATUS: 'PE', EREC_PLAN: null, EREC_ACTUAL: null, },
      { MOD: '41M001', BLK: '3C1', TYPE: 'UNIT', DEP: 21.4, LEN: 16.3, HEI: 3.9, WT: 64.6, STATUS: 'PE', EREC_PLAN: null, EREC_ACTUAL: null, },
      { MOD: '41M001', BLK: '3X6', TYPE: 'UNIT', DEP: null, LEN: null, HEI: null, WT: null, STATUS: 'PE', EREC_PLAN: null, EREC_ACTUAL: null, },
      { MOD: '41M001', BLK: '3AA', TYPE: 'PE', DEP: null, LEN: null, HEI: null, WT: null, STATUS: 'Erection', EREC_PLAN: '2019-05-10', EREC_ACTUAL: '2019-05-24', },
      { MOD: '41M001', BLK: '3A2', TYPE: 'BLK', DEP: 6.7, LEN: 16.3, HEI: 1.5, WT: 47.1, STATUS: 'Erection', EREC_PLAN: '2019-03-05', EREC_ACTUAL: '2019-03-05', },
      { MOD: '41M001', BLK: '3A3', TYPE: 'BLK', DEP: 25.3, LEN: 16.3, HEI: 1.5, WT: 437.9, STATUS: 'Erection', EREC_PLAN: '2019-03-04', EREC_ACTUAL: '2019-03-04', },
      { MOD: '41M001', BLK: '3A4', TYPE: 'BLK', DEP: 10.8, LEN: 16.3, HEI: 1.5, WT: 85.2, STATUS: 'Erection', EREC_PLAN: '2019-02-25', EREC_ACTUAL: '2019-02-25', },
      { MOD: '41M001', BLK: '3B2', TYPE: 'BLK', DEP: 11.9, LEN: 4.3, HEI: 9.8, WT: 24.4, STATUS: 'Erection', EREC_PLAN: '2019-04-24', EREC_ACTUAL: '2019-04-26', },
      { MOD: '41M001', BLK: '3B3', TYPE: 'BLK', DEP: 11.9, LEN: 4.2, HEI: 9.8, WT: 19.5, STATUS: 'Erection', EREC_PLAN: '2019-04-30', EREC_ACTUAL: '2019-05-03', },
      { MOD: '41M001', BLK: '3B4', TYPE: 'BLK', DEP: 17.9, LEN: 4.3, HEI: 9.8, WT: 49.2, STATUS: 'Erection', EREC_PLAN: '2019-04-23', EREC_ACTUAL: '2019-04-26', },
      { MOD: '41M001', BLK: '3B5', TYPE: 'BLK', DEP: 17.9, LEN: 4.2, HEI: 9.8, WT: 49.3, STATUS: 'Erection', EREC_PLAN: '2019-04-29', EREC_ACTUAL: '2019-05-03', },
      { MOD: '41M001', BLK: '3B6', TYPE: 'BLK', DEP: 4, LEN: 16.3, HEI: 9.8, WT: 38.9, STATUS: 'Erection', EREC_PLAN: '2019-04-30', EREC_ACTUAL: '2019-05-03', },
      { MOD: '41M001', BLK: '3B7', TYPE: 'BLK', DEP: 24.1, LEN: 8.8, HEI: 1.3, WT: 18.7, STATUS: 'Erection', EREC_PLAN: '2019-04-22', EREC_ACTUAL: '2019-04-26', },
      { MOD: '41M001', BLK: '3B8', TYPE: 'BLK', DEP: 5.6, LEN: 16.3, HEI: 3.6, WT: 28.2, STATUS: 'Erection', EREC_PLAN: '2019-05-03', EREC_ACTUAL: '2019-05-10', },
      { MOD: '41M001', BLK: '3C2', TYPE: 'BLK', DEP: 15.9, LEN: 13.9, HEI: 0.5, WT: 24.2, STATUS: 'Erection', EREC_PLAN: '2019-05-08', EREC_ACTUAL: '2019-05-11', },
      { MOD: '41M001', BLK: '3C3', TYPE: 'BLK', DEP: 7.2, LEN: 7.3, HEI: 0.5, WT: 6.6, STATUS: 'Erection', EREC_PLAN: '2019-05-03', EREC_ACTUAL: '2019-05-09', },
      { MOD: '41M001', BLK: '3C4', TYPE: 'BLK', DEP: 10.9, LEN: 13.9, HEI: 0.5, WT: 24.2, STATUS: 'Erection', EREC_PLAN: '2019-05-07', EREC_ACTUAL: '2019-05-09', },
      { MOD: '41M001', BLK: '3C5', TYPE: 'BLK', DEP: 9.5, LEN: 16.3, HEI: 3.6, WT: 49.2, STATUS: 'Erection', EREC_PLAN: '2019-05-17', EREC_ACTUAL: '2019-05-31', },
      { MOD: '41M001', BLK: '3C9', TYPE: 'BLK', DEP: null, LEN: null, HEI: null, WT: 6.3, STATUS: 'Erection', EREC_PLAN: '2019-04-23', EREC_ACTUAL: '2019-04-23', },
      { MOD: '41M001', BLK: '3D1', TYPE: 'UNIT', DEP: 24, LEN: 16.3, HEI: 1, WT: 112.3, STATUS: 'PE', EREC_PLAN: null, EREC_ACTUAL: null, },
      { MOD: '41M001', BLK: '3F1', TYPE: 'UNIT', DEP: 20, LEN: 16.3, HEI: 5.7, WT: 131.1, STATUS: 'PE', EREC_PLAN: null, EREC_ACTUAL: null, },
      { MOD: '41M001', BLK: '3DA', TYPE: 'PE', DEP: null, LEN: null, HEI: null, WT: null, STATUS: 'Erection', EREC_PLAN: '2019-06-05', EREC_ACTUAL: '2019-06-21', },
      { MOD: '41M001', BLK: '3D3', TYPE: 'UNIT', DEP: 25, LEN: 16.3, HEI: 5.9, WT: 137, STATUS: 'PE', EREC_PLAN: null, EREC_ACTUAL: null, },
      { MOD: '41M001', BLK: '3F2', TYPE: 'UNIT', DEP: 8.8, LEN: 16.3, HEI: 4.9, WT: 150.2, STATUS: 'PE', EREC_PLAN: null, EREC_ACTUAL: null, },
      { MOD: '41M001', BLK: '3DB', TYPE: 'PE', DEP: null, LEN: null, HEI: null, WT: null, STATUS: 'Erection', EREC_PLAN: '2019-05-31', EREC_ACTUAL: '2019-06-07', },
      { MOD: '41M001', BLK: '3D2', TYPE: 'BLK', DEP: 18.8, LEN: 16.3, HEI: 5.9, WT: 100, STATUS: 'Erection', EREC_PLAN: '2019-05-20', EREC_ACTUAL: '2019-06-07', },
      { MOD: '41M001', BLK: '3D9', TYPE: 'BLK', DEP: null, LEN: null, HEI: null, WT: null, STATUS: 'Erection', EREC_PLAN: '2019-05-20', EREC_ACTUAL: '2019-05-23', },
      { MOD: '41M001', BLK: '3F3', TYPE: 'BLK', DEP: 4, LEN: 16.3, HEI: 5.4, WT: 19.1, STATUS: 'Erection', EREC_PLAN: '2019-06-12', EREC_ACTUAL: '2019-06-21', },
      { MOD: '41M001', BLK: '3F4', TYPE: 'BLK', DEP: 6.5, LEN: 6.5, HEI: 4.9, WT: 10.7, STATUS: 'Erection', EREC_PLAN: '2019-06-03', EREC_ACTUAL: '2019-06-14', },
      { MOD: '41M001', BLK: '3G1', TYPE: 'BLK', DEP: 17.6, LEN: 16.3, HEI: 4.8, WT: 46, STATUS: 'Erection', EREC_PLAN: '2019-07-03', EREC_ACTUAL: '2019-07-19', },
      { MOD: '41M001', BLK: '3G2', TYPE: 'BLK', DEP: 19.4, LEN: 16.3, HEI: 5.4, WT: 67.6, STATUS: 'Erection', EREC_PLAN: '2019-06-19', EREC_ACTUAL: '2019-07-05', },
      { MOD: '41M001', BLK: '3G3', TYPE: 'BLK', DEP: 17.6, LEN: 16.3, HEI: 5.4, WT: 66, STATUS: 'Erection', EREC_PLAN: '2019-06-20', EREC_ACTUAL: '2019-07-12', },
      { MOD: '41M001', BLK: '3G4', TYPE: 'BLK', DEP: 6, LEN: 16.3, HEI: 5.4, WT: 41.5, STATUS: 'Erection', EREC_PLAN: '2019-06-27', EREC_ACTUAL: '2019-07-12', },
      { MOD: '41M001', BLK: '3G9', TYPE: 'BLK', DEP: null, LEN: null, HEI: null, WT: 6.1, STATUS: 'Erection', EREC_PLAN: '2019-05-20', EREC_ACTUAL: '2019-05-23', },
      { MOD: '41M001', BLK: '3H3', TYPE: 'UNIT', DEP: 17, LEN: 16.3, HEI: 1, WT: 136.9, STATUS: 'PE', EREC_PLAN: null, EREC_ACTUAL: null, },
      { MOD: '41M001', BLK: '3J6', TYPE: 'UNIT', DEP: 16.5, LEN: 16.3, HEI: 4.6, WT: 103.5, STATUS: 'PE', EREC_PLAN: null, EREC_ACTUAL: null, },
      { MOD: '41M001', BLK: '3K5', TYPE: 'UNIT', DEP: 16.8, LEN: 16.3, HEI: 3, WT: 88.5, STATUS: 'PE', EREC_PLAN: null, EREC_ACTUAL: null, },
      { MOD: '41M001', BLK: '3HA', TYPE: 'PE', DEP: null, LEN: null, HEI: null, WT: null, STATUS: 'Erection', EREC_PLAN: '2019-07-26', EREC_ACTUAL: '2019-08-09', },
      { MOD: '41M001', BLK: '3H1', TYPE: 'BLK', DEP: 26.2, LEN: 16.3, HEI: 1, WT: 358.8, STATUS: 'Erection', EREC_PLAN: '2019-07-25', EREC_ACTUAL: '2019-08-23', },
      { MOD: '41M001', BLK: '3H2', TYPE: 'BLK', DEP: 24.6, LEN: 16.3, HEI: 1, WT: 349.1, STATUS: 'Erection', EREC_PLAN: '2019-07-24', EREC_ACTUAL: '2019-08-16', },
      { MOD: '41M001', BLK: '3J2', TYPE: 'UNIT', DEP: 22, LEN: 2.5, HEI: 6.2, WT: 46.4, STATUS: 'PE', EREC_PLAN: null, EREC_ACTUAL: null, },
      { MOD: '41M001', BLK: '3J4', TYPE: 'UNIT', DEP: 23, LEN: 2.5, HEI: 6.2, WT: 46.4, STATUS: 'PE', EREC_PLAN: null, EREC_ACTUAL: null, },
      { MOD: '41M001', BLK: '3JA', TYPE: 'PE', DEP: null, LEN: null, HEI: null, WT: null, STATUS: 'Erection', EREC_PLAN: '2019-09-16', EREC_ACTUAL: '2019-10-04', },
      { MOD: '41M001', BLK: '3J3', TYPE: 'UNIT', DEP: 22, LEN: 4.1, HEI: 6.2, WT: 46.4, STATUS: 'PE', EREC_PLAN: null, EREC_ACTUAL: null, },
      { MOD: '41M001', BLK: '3J5', TYPE: 'UNIT', DEP: 23, LEN: 4.1, HEI: 6.2, WT: 46.4, STATUS: 'PE', EREC_PLAN: null, EREC_ACTUAL: null, },
      { MOD: '41M001', BLK: '3JB', TYPE: 'PE', DEP: null, LEN: null, HEI: null, WT: null, STATUS: 'PE', EREC_PLAN: '2019-08-19', EREC_ACTUAL: null, },
      { MOD: '41M001', BLK: '3J1', TYPE: 'BLK', DEP: 3.4, LEN: 16.3, HEI: 6.2, WT: 22.1, STATUS: 'Erection', EREC_PLAN: '2019-09-19', EREC_ACTUAL: '2019-10-04', },
      { MOD: '41M001', BLK: '3J7', TYPE: 'BLK', DEP: 3, LEN: 5, HEI: 9, WT: 11.1, STATUS: 'Erection', EREC_PLAN: '2019-09-27', EREC_ACTUAL: '2019-10-11', },
      { MOD: '41M001', BLK: '3K1', TYPE: 'BLK', DEP: 1, LEN: 9.5, HEI: 6.2, WT: 2.4, STATUS: 'Erection', EREC_PLAN: '2019-08-23', EREC_ACTUAL: '2019-09-06', },
      { MOD: '41M001', BLK: '3K2', TYPE: 'BLK', DEP: 1, LEN: 9.5, HEI: 6.2, WT: 2.4, STATUS: 'Erection', EREC_PLAN: '2019-08-23', EREC_ACTUAL: '2019-09-06', },
      { MOD: '41M001', BLK: '3K3', TYPE: 'BLK', DEP: 1, LEN: 9.5, HEI: 6.2, WT: 2.4, STATUS: 'Erection', EREC_PLAN: '2019-08-21', EREC_ACTUAL: '2019-09-06', },
      { MOD: '41M001', BLK: '3K4', TYPE: 'BLK', DEP: 1, LEN: 9.5, HEI: 6.2, WT: 2.4, STATUS: 'Erection', EREC_PLAN: '2019-08-21', EREC_ACTUAL: '2019-09-06', },
      { MOD: '41M001', BLK: '3X3', TYPE: 'BLK', DEP: null, LEN: null, HEI: null, WT: null, STATUS: 'Erection', EREC_PLAN: '2019-05-21', EREC_ACTUAL: '2019-06-07', },
      { MOD: '41M001', BLK: '3X4', TYPE: 'BLK', DEP: null, LEN: null, HEI: null, WT: null, STATUS: 'Erection', EREC_PLAN: '2019-06-03', EREC_ACTUAL: '2019-06-21', },
      { MOD: '41M001', BLK: '3X5', TYPE: 'BLK', DEP: null, LEN: null, HEI: null, WT: null, STATUS: 'PE', EREC_PLAN: '2019-09-27', EREC_ACTUAL: null, },
    ],


    // Invoke the Database Support / SQL Queries ???
    // SQL1: {
    //   datasourceType: String    // 'manual' | 'database',
    //   datasourceId: Number,
    //   datasource: [
    //     ...
    //   ]
    // }
    Queries: {
      SQL1: [
        {RFS:"2020",MOD:"41M001",MOD_DESC:"PBF Compressor - Train 4",DEL_LEAD:"Zaigul Dossaliyeva",LEN:70,WID:16.3,HEI:33.75,WT:4600.44,PAU:3,BLK:55,TSCD:"2018-11-02",RFS_ESD:"2020-06-09",MC_TARGET:"2020-04-05",QUARTER:"2nd-2020",A1_T:12426,A1_A:1610,A1_O:10816,A1_PG:13,B1_T:1483,B1_A:80,B1_O:1403,B1_PG:5.4,D1_T:678,D1_A:0,D1_O:678,D1_PG:0,PA:452,PB:217,LI_T:242,LI_A:146,HT_T:242,HT_A:137,RE_T:309,RE_A:88,WD_T:151,WD_A:4,DAC_T:76,DAC_A:0,FMC_T:86,FMC_A:0,STR_M_PP:86.8,STR_A_PP:97.1,PIP_M_PP:63.1,PIP_A_PP:69.3,ENI_M_PP:28.6,ENI_A_PP:35.3,MEC_M_PP:55,MEC_A_PP:90.6,HVC_M_PP:65.8,HVC_A_PP:69.5,ARC_M_PP:36.6,ARC_A_PP:44.6,PNT_M_PP:85.9,PNT_A_PP:87.5,INU_M_PP:4.9,INU_A_PP:0,OV_M_PP:60.7,OV_A_PP:67.3,CUT_T:50,CUT_A:50,ASSY_T:50,ASSY_A:50,PNT_T:50,PNT_A:50,EREC_T:39,EREC_A:39,ST010_ESD:"2018-10-09",ST010_EFD:"2019-07-03",ST010_ASD:"2018-10-10",ST010_AFD:"2019-05-14",ST010_P:100,ST010_A:100,ST030_ESD:"2018-10-17",ST030_EFD:"2019-07-12",ST030_ASD:"2018-10-17",ST030_AFD:"2019-06-12",ST030_P:100,ST030_A:100,ST080_ESD:"2019-01-22",ST080_EFD:"2019-09-27",ST080_ASD:"2019-01-25",ST080_AFD:"2019-10-11",ST080_P:100,ST080_A:100,PI030_ESD:"2019-02-15",PI030_EFD:"2020-02-07",PI030_ASD:"2019-02-15",PI030_P:86.3,PI030_A:92.7,PI060_P:40.5,PI060_A:76.7,PI070_P:25.9,PI070_A:49,PI080_P:14.6,PI080_A:47.4,PI090_P:8.6,PI090_A:32.1,EI060_ESD:"2019-07-08",EI060_EFD:"2020-03-06",EI060_ASD:"2019-07-24",EI060_P:37.3,EI060_A:65.3,EI070_P:9.7,EI070_A:31.3,HV020_P:84.1,HV020_A:84.4,HV040_P:71.9,HV040_A:100,MC_ESD:"2018-12-14",MC_EFD:"2020-04-03",MC_ASD:"2018-12-14",MC_P:75.1,MC_A:40.9,OT090_ESD:"2020-06-09",OT090_EFD:"2020-06-09",OT090_P:0,OT090_A:0}
      ]
    },
}