<template>
  <div>
    <v-dialog 
      fullscreen 
      hide-overlay 
      transition="slide-full-modal-transition" 
      v-model="formOpened" content-class="j_modal" persistent>
      <v-card>
        <div class="modal__header full_title pd_left_3">
          <button class="hamberger_menu" type="button"  @click="onAction('cancel')">
            <v-icon>mdi-menu</v-icon>
          </button>
          <span>SVG Control <span class="sub_title">{{ `${idxNum} ${subTitle}` }}</span></span>
        </div>

        <v-card-text class="modal__formControl dark_theme properties7 type2">
          <!-- <v-form v-model="valid" ref="form"> -->
            <v-container>
              <v-card-actions class="modal_toolbar">
                <div class="tool_menus">
                  <div 
                    data-common-quickbtn='normal'
                    title="general"
                    :class="typeName == __C_.DATATABLE.TYPE_CODE_GENERAL ? 'selected': ''"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </div>
                  <div 
                    data-pie-quickbtn="timeline-extended" 
                    title="timeline-extended"
                    :class="typeName == __C_.DATATABLE.TYPE_CODE_TIMELINE ? 'selected': ''"
                  ></div>
                </div>
              </v-card-actions>
              <div class="modal_dark_btn extra_properties_summary">
                <j-button
                  class="type01 sky  type_full "
                  :class="{ disabled: !resetable }"
                  :disabled="!resetable"
                  @click="onAction('save')"
                >Save</j-button>
                <j-button v-if="modeMod" class="type01 delete  type_full" @click="onAction('delete')">Delete</j-button>
                <div class="check_wrapper">
                  <v-checkbox v-model="update" :label="'Update'"/>
                  <v-checkbox :label="'Page Svg'" />
                  <v-checkbox :label="'Fixed'" />
                </div>
                <button class="help" @click="(e) => { helper=!helper }"></button>
              </div>          

              <div class="modal__formControl_inside">
                <v-tabs v-model="stepper" class="wrap__modal_wfix">
                  <v-tab data-type="navigation" title="navigation"></v-tab>
                  <v-tab data-type="database" title="database"></v-tab>
                  <v-tab data-type="canvas" title="canvas"></v-tab>
                  <v-tab data-type="status" title="status"></v-tab>
                  <v-tab data-type="border" title="border"></v-tab>
                  <v-tab data-type="forms" title="forms"></v-tab>
                  <v-tab data-type="note" title="note"></v-tab>

                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-navigation ref="general" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-database ref="database" type="datatable" @save="onDBSave" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-canvas />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-status />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-border />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-forms />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-note />
                  </v-tab-item>
                </v-tabs>

                <j-svg-block-division
                  class="canvasAreaLine"
                  
                  :Canvas="canvas"
                  :Note="note"
                  :Status="style"
                  :Border="border"

                  :style="`--area-width: ${this.canvas.CanvasWidth + 1}px; --area-height: ${this.canvas.CanvasHeight + 1}px;`"

                  :ChartData="chartData"
                  :DataItems="dataItems"
                />

                <div v-if="helper" id="j-chart-helper">
                  <img :src="helperUrl" />
                </div>
              </div>

            </v-container>
          <!-- </v-form> -->
        </v-card-text>
      </v-card>
    </v-dialog>
    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :title-description="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :button-text="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import _DataDefault from '@/primitives/_pageSvgControlDefault'
import ChartModalMixin from "@/mixins/chart.modal.common"
import { mapState, mapMutations, mapGetters, mapActions } from "vuex"
import { PageComponentService } from "@/services"
import JSysenvTabProps from './SysenvPageSvgControl'

export default {
  name: 'sysenv-page-svg-control-modal',
  mixins: [
    ChartModalMixin
  ],
  components: {
    ...JSysenvTabProps
  },
  props: {
    itemKey: null,
    value: { type: Boolean, default: false }
  },
  data: () => ({
    // Service Instances -------------
    pageComponentService: null,
    // activators --------------------
    msgOpen: false,
    // general -----------------------
    dimention: {},
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    prevItem: {},
    stepper: 0,
    valid: false,
    update: true,
    idxNum: '',
    subTitle: ''
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.SVGCONTROL_ADMIN, [
      'chartData', 'dataItems', 'svgProps'
    ]),
    ...mapGetters(__C.STORE_NAMESPACE.SVGCONTROL_ADMIN, [
      'border', 'style'
    ]),
    ...mapGetters(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, [
      'general', 'canvas', 'note'
    ]),

    __C_() { return __C },

    codePropagated: {
      get() { return this.$store.state.sysenv.codePropagated },
      set(value) { this.$store.commit('sysenv/codePropagate', value) }
    },
    modeNew() { return this.formMode == __C.FORM.EDIT_MODE_NEW },
    modeMod() { return this.formMode == __C.FORM.EDIT_MODE_MOD },
    formMode() {
      if (this.itemKey) return __C.FORM.EDIT_MODE_MOD
      else return __C.FORM.EDIT_MODE_NEW
    },
    formOpened: {
      get() { return this.value },
      set(val) { this.$emit('input', val) }
    },
    headers() {
      if (!this.svgProps.jsonProps) return []

      try { var jsonProps_ = JSON.parse(this.svgProps.jsonProps) }
      catch { return [] }

      return jsonProps_
    },
    updated: {
      get() { return this.$store.state.sysenv.menuUpdated },
      set(value) { this.$store.commit("sysenv/setMenuStatusUpdated", value) }
    },
    typeName() { return '' },
    helperUrl() { return this.chartItem.ChartNo && this.helper ? require(`../../../assets/helpDoc/logo.png`) : '' },

  },
  watch: {
    formOpened(val) {
      if (!val) return

      this.init()

      if (this.modeNew) {
        this.setSvgProps({
          directAccess: 'Y',
          qApplied: 'J',
          canvas: _DataDefault.Canvas,
          style: _DataDefault.Status,
          border: _DataDefault.Border,
          forms: {
            background: { fileUrl: '', x: 0, y: 0 },
            svg: { folderUrl: '', x: 0, y: 0 },
            keyPlan: { fileUrl: '', x: 0, y: 0 },
            legends: { fileUrl: '', x: 0, y: 0 }
          },
          note: _DataDefault.Note,
          chartData: _DataDefault.ChartData,
          jsonString: JSON.stringify(_DataDefault.DataItems),
        })
        this.setChartData(_DataDefault.ChartData)
        this.setDataItems(_DataDefault.DataItems)

        // To utilize the Chart's common component
        this.setChartItem(_DataDefault.Canvas)
        this.setChartItem(_DataDefault.Note)
        
        this.$refs.general.setData(this.formMode)

      } else {
        this.getSvgProps().then((res) => {
          // To utilize the Chart's common component
          this.setChartItem({
            Name: res.name,
            PhaseCode: res.phaseCode,
            GroupCode: res.groupCode,
            ItemCode: res.itemCode,
            SubItem: res.subItem,
            ...res.canvas,
            ...res.note,
          })
          // Temporary assign the default values...
          this.setChartData(_DataDefault.ChartData)
          // ...
          this.setDataItems(res.qApplied == 'J' ? JSON.parse(res.jsonString) : [])
          this.setSvgProps(res)

          this.$refs.general.setData(this.formMode)
          this.$refs.database.setData(this.formMode)
        })
      }
    }
  },
  created() {
    this.pageComponentService = new PageComponentService()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.SVGCONTROL_ADMIN, [
      'setChartData', 'setDataItems', 'setEmpty', 'setSvgProps'
    ]),
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, [
      'setChartItem'
    ]),

    itemIdx(Idx) {
      this.idxNum = `(${Idx})`
    },
    itemName(val) {
      this.subTitle = val
    },

    // CRUD Process
    getSvgProps() {
      return new Promise((resolve) => {
        this.pageComponentService.getSvgProps(this.itemKey, (res) => {
          this.itemIdx(res.idx)
          this.itemName(res.name)
          resolve(res)
        })
      })
    },
    onAction(action) {
      if (action == 'save') this.modeNew ? this.add() : this.edit()
      else if (action == 'delete') this.del()
      else this.close()
    },
    onDBSave() {
      if(this.modeNew) return
    },

    add() {
      // set SVG Properties with the Chart Items' properties.
      this.setSvgProps({
        name: this.general.Name,
        phaseCode: this.general.PhaseCode,
        groupCode: this.general.GroupCode,
        itemCode: this.general.ItemCode,
        subItem: this.general.SubItem,
        canvas: JSON.parse(JSON.stringify(this.canvas)),
        note: JSON.parse(JSON.stringify(this.note))
      })

      this.pageComponentService.putSvgProps(this.svgProps, () => {
        this.close()
        this.$emit("updated", true)
        this.menuUpdated = true
      })
    },
    edit() {
      // set SVG Properties with the Chart Items' properties.
      this.setSvgProps({
        name: this.general.Name,
        phaseCode: this.general.PhaseCode,
        groupCode: this.general.GroupCode,
        itemCode: this.general.ItemCode,
        subItem: this.general.SubItem,
        canvas: JSON.parse(JSON.stringify(this.canvas)),
        note: JSON.parse(JSON.stringify(this.note))
      })

      this.pageComponentService.updSvgProps(this.svgProps, res => {
        this.close()
        this.$emit("updated", true)
      })
    },
    del() {
      this.yes = () => {
        this.msgOpen = false
        this.pageComponentService.delSvgProps(this.svgProps.idx, res => {
          this.close()
          this.$emit("updated", true)
          this.menuUpdated = true
        })
        this.yes = () => { }
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Action Approval"
      this.msgInfo.titleDescription = "Important Notification"
      this.msgInfo.message = "Do you want to delete current information?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    init() {
      this.stepper = 0
      // every time came into the modal, either the mode new or edit,
      // whole the store's data should be initialized.
      this.setEmpty()
      this.prevItem = {}
      this.dimention = {}
    },
    resetable() {
      if (!this.chartMaster) return false
      if (this.modeNew) {
        return false
      } else {
        return false
      }
    },
    close() {
      this.formOpened = false
    },
  }
}
</script>
